<template>
    <div class="center mb-3 divider-wrapper">
        <h1 v-if="secondLine === ''" class="title font-weight-bold">{{ sectionTitle }}</h1>
        <h1 v-else class="title">{{ sectionTitle }} <br /> {{ secondLine }}</h1>
    </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    sectionTitle: {
			type: String,
			default: () => ""
		},
    secondLine: {
			type: String,
			default: () => ""
    },
    step: {
			type: String,
			default: () => ""
    },
  }
}
</script>

<style lang="scss">
.divider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        font-size: 24px;
        // white-space: nowrap;
        text-align: center;
        color: var(--primary-color);
        margin-bottom: 0 !important;
        margin-right: 10px;
    }

    .divider {
        flex: 9.5;
        height: 2px;
        background-color: var(--primary-color);
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--primary-color);
            transform: translateY(-4px);
        }
    }
}
</style>